import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = () => (
  <div
    style={{
      padding: "4rem 1rem",
      textAlign: "center",
      marginTop: "5vh",
      marginBottom: "15vh",
    }}
  >
    <SectionHeader
      title="Contact us"
      description="Ask us anything! For information about bulk sale and influencer promotion, email contact@hefa.lt directly from your email."
    />
    <Button href="https://hefa.lt/pages/contact-us">Get in touch</Button>
  </div>
)

export default CallToAction
