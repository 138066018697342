import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import headerImage from "../images/header.png"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>HEFA</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>Explore our stores</p>

    <div class="h-section text-center showcase">
      <a href="https://outlet.hefa.lt" class="showcase-child card-title">
        <div class="card card-panel shadow">
          <img src="/hefa.png" alt="hefa" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Hefa Outlet
          </div>
        </div>
      </a>

      <a
        href="https://www.depop.com/oldfinds/"
        class="showcase-child card-title "
      >
        <div class="card card-panel shadow">
          <img src="/oldfinds.jpg" alt="oldfinds" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Oldfinds DEPOP
          </div>
        </div>
      </a>

      <a href="https://www.depop.com/stilt/" class="showcase-child card-title">
        <div class="card card-panel shadow">
          <img src="/stilt.jpg" alt="stilt" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Stilt
          </div>
        </div>
      </a>

      <a
        href="https://www.depop.com/verilux/"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/verilux.jpg" alt="verilux" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Verilux
          </div>
        </div>
      </a>

      <a
        href="https://www.sobump.com/@highvintage"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/highvintage.jpg" alt="highvintage" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            HighVintage
          </div>
        </div>
      </a>

      <a
        href="https://www.sobump.com/@regarms"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/regarms.jpg" alt="regarms" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Regarms
          </div>
        </div>
      </a>

      <a href="https://www.depop.com/hepha/" class="showcase-child card-title">
        <div class="card card-panel shadow">
          <img src="/hepha.jpg" alt="hepha" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Hepha DEPOP
          </div>
        </div>
      </a>

      <a
        href="https://www.depop.com/garmentide/"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/garmentide.jpg" alt="garmentide" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Garmentide
          </div>
        </div>
      </a>

      <a
        href="https://www.etsy.com/shop/OldFindsVintage"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/oldfindsetsy.png" alt="oldfindsetsy" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Oldfinds ETSY
          </div>
        </div>
      </a>

      <a
        href="https://www.depop.com/pastreborn/"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/pastreborn.jpg" alt="garmentide" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Past Reborn
          </div>
        </div>
      </a>

      <a
        href="https://www.depop.com/coldcalt/"
        class="showcase-child card-title"
      >
        <div class="card card-panel shadow">
          <img src="/coldcalt.jpg" alt="coldcalt" />

          <div
            style={{ margin: "0 auto" }}
            class="text-center font-weight-bold "
          >
            Coldcalt
          </div>
        </div>
      </a>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
